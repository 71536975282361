import { create } from "zustand";

interface Product {
  Id: number;
  Category: string;
  Name: string;
  Squ: number;
  Vip: string;
  Description: string;
  Price: number;
  SalePrice: number;
  Images: string[];
  AttributeTitles: {
    Id: number;
    Name: string;
  }[];
  Attributes: {
    Id: string;
    Order: number;
    Values: {
      Id: number;
      Name: string;
      Value: string;
    }[];
  }[];
}

interface ApplicationState {
  search: string;
  updateSearch: (value: string) => void;
  activeTab: string;
  setActiveTab: (name: string) => void;
  _items: Product[];
  fetchItems: () => void;
  tabs: () => string[];
  items: () => Product[];
}

export const useApplicationStore = create<ApplicationState>((set, get) => ({
  search: "",
  updateSearch: (value: string) => {
    set({ search: value });
  },
  activeTab: "",
  setActiveTab: (name: string) => {
    set({ activeTab: name });
    set({ search: "" });
  },
  _items: [],
  fetchItems: async () => {
    try {
      const response = await fetch(
        // "http://185.69.153.153/ds/products.json?v=" + Date.now(),
        "./products.json?v=" + Date.now(),
      );
      set({ _items: await response.json() });
    } catch (ex) {
      console.log("parsing failed", ex);
    }
  },
  items: () => {
    let _items = get()._items;

    if (get().search) {
      _items = _items.filter(
        (x) => x.Squ.toString().indexOf(get().search) !== -1,
      );
    }

    if (get().activeTab) {
      _items = _items.filter((x) => x.Category === get().activeTab);
    }

    return _items;
  },
  tabs: () =>
    get()
      ._items.map((item) => item.Category)
      .filter((value, index, self) => self.indexOf(value) === index),
}));
