import { useEffect } from "react";
import { useApplicationStore } from "./ApplicationStore";
import { IndexPage } from "./IndexPage";

// aws s3
// AKIAXJQRSZD76BNPMZWO
// qtCuZWu4UmG8F8EnVKx1EmQ2i6XqP0X8FLoFvyId

export const App = () => {
  const store = useApplicationStore();

  useEffect(() => {
    store.fetchItems();
  }, []);

  return <IndexPage />;
};
