import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./style.css";

document.body.innerHTML = '<div id="__inventor"></div>';

const appNode = document.getElementById("__inventor");
if (appNode === null) throw new Error("Root container missing in index.html");

const root = ReactDOM.createRoot(appNode);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
