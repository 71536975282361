import { useApplicationStore } from "./ApplicationStore";

export const IndexPage = () => {
  const store = useApplicationStore();

  return (
    <div className="content container">
      <div className="btn-group btn-group-block">
        <input
          name="search"
          id="__search"
          placeholder="search..."
          value={store.search}
          onChange={(e) => {
            store.updateSearch(e.currentTarget.value);
          }}
        />
        {store.tabs().map((tab, index) => (
          <button
            className={tab === store.activeTab ? "btn active" : "btn"}
            key={tab + "_" + index}
            onClick={(e) => {
              e.preventDefault();
              store.setActiveTab(tab);
            }}
          >
            {tab}
          </button>
        ))}
      </div>
      {store.items().map((item, index) => (
        <div className="item" key={item.Squ + "_" + index}>
          <div className="grid__flex grid__display grid__column size__250">
            <div className="border__t border__b border__l decorate__head text__bold">
              Артикул
            </div>
            <div className="border__b border__l decorate__squ text__right">
              {item.Squ}
            </div>
            <div className="grid__flex border__b border__l">
              {item.Images.length > 0 && (
                <img
                  loading="lazy"
                  className="decorate__image"
                  alt={item.Name}
                  src={item.Images[0]}
                />
              )}
            </div>
          </div>

          <div className="grid__display grid__flex grid__column border__t border__r border__b border__l size__500">
            <div className="grid__display grid__column">
              <div className="grid__display border__b">
                {item.AttributeTitles.map(
                  (attributeTitle, attributeTitleIndex) => {
                    let title = attributeTitle.Name;

                    if (title === "Размер") title = "Розмір";
                    if (title === "Количество") title = "Кількість";

                    return (
                      <div
                        className={
                          attributeTitleIndex === 0
                            ? "grid__flex decorate__head text__bold "
                            : "grid__flex decorate__head text__bold border__l"
                        }
                        key={attributeTitle.Id + "_" + attributeTitleIndex}
                      >
                        {title}
                      </div>
                    );
                  },
                )}
              </div>
              {item.Attributes.map((attribute, attributeIndex) => {
                return (
                  <div
                    className="grid__display border__b"
                    key={attribute.Id + "_" + attributeIndex}
                  >
                    {attribute.Values.map(
                      (attributeValue, attributeValueIndex) => {
                        let classNames = ["grid__flex"];

                        if (attributeValueIndex !== 0)
                          classNames.push("border__l");
                        if (attributeValueIndex < 2)
                          classNames.push("decorate__quantity");
                        if (attributeValueIndex > 1)
                          classNames.push("decorate__squ");

                        return (
                          <div
                            className={classNames.join(" ")}
                            key={attributeValue.Id + "_" + attributeValueIndex}
                          >
                            {attributeValue.Value}
                          </div>
                        );
                      },
                    )}
                  </div>
                );
              })}
            </div>
            <div className="grid__display grid__column">
              <div className="grid__display">
                {item.Vip === "1" && (
                  <div className="grid__flex decorate__vip1 border__b text__left">
                    Vip
                  </div>
                )}
                {item.Vip === "2" && (
                  <div className="grid__flex decorate__vip2 border__b text__left">
                    Vip
                  </div>
                )}
              </div>
              <div className="grid__display ">
                <div className="grid__flex decorate__price text__bold">
                  Дроп
                </div>
                <div className="grid__flex border__l decorate__price text__bold">
                  Роздріб
                </div>
              </div>
              <div className="grid__display border__t">
                <div className="grid__flex decorate__price">{item.Price}</div>
                <div className="grid__flex border__l decorate__price">
                  {item.SalePrice}
                </div>
              </div>
              <div className="border__t">
                <div className="border__b decorate__head text__bold text__right">
                  Опис
                </div>
                <div className="decorate__cell text__left">
                  {item.Description}
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
